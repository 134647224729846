.first{
	height: 100vh;
	background-image: url('/src/images/first.jpg');
	background-position: center;
	background-size: cover;
	text-align: center;
	position: relative;
	&__menu{
		width: 1100px;
		ul{
			width: 100%;
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: space-between;
		}
		ul, li{
			list-style: none;
		}
		a{
			display: block;
			text-transform: uppercase;
			text-decoration: none;
			color: #fff;
			line-height: 100px;
			padding: 0 15px;
			span{
				border-bottom: 1px solid #fff;
				transition: border 0.3s ease;
			}
			&:hover{
				span{
					border-color: transparent;
				}
			}
		}
	}
	&__logo{
		width: 634px;
		height: 99px;
		background-image: url('/src/images/logo.png');
		background-position: center;
		background-size: container;
		position: absolute;
		top: 35%;
		left: calc(50% - 317px);
	}
	&__slogan{
		width: 100%;
		font-size: 62px;
		line-height: 62px;
		font-weight: 900;
		color: #fff;
		position: absolute;
		top: calc(35% + 100px);
	}
	&__text{
		width: 100%;
		position: absolute;
		top: calc(35% + 200px);
	}
	&__text,
	p{
		font-size: 22px;
		line-height: 28px;
		color: #fff;
		letter-spacing: -0.2px;
	}
	&__next{
		width: 100%;
		font-size: 22px;
		color: #fff;
		position: absolute;
		bottom: 15%;
		&:after{
			width: 0;
			display: block;
			content: '';
			border: 13px solid transparent;	
			border-top: 13px solid $orange;
			position: absolute;
			bottom: -30px;
			left: calc(50% - 13px);
		}
	}
}