.sect3{
	padding-bottom: 100px;
	&__left{
		width: 600px;
		float: left;
	}
	&__right{
		width: calc(100% - 600px);
		float: left;
	}
	
	&__sl{
		&__item{
			&__desc{
				display: flex;
				font-weight: bold;
				margin-top: 45px;
			}
			&__num{
				font-size: 60px;
				line-height: 52px;
				margin-right: 10px;
				color: $orange;
			}
			&__text{
				font-size: 22px;
				line-height: 26px;
			}
		}
		.owl-nav{
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		.owl-prev,
		.owl-next{
			position: absolute;
			top: 165px;
			width: 60px;
			height: 120px;
			&:before,
			&:after{
				display: block;
				content: '';
				width: 1px;
				height: 86px;
				background: #ccc;
				position: absolute;
			}
			&:before{
				top: 0;
			}
			&:after{
				bottom: 0;
			}
		}
		.owl-prev{
			left: -100px;
			&:before,
			&:after{
				right: 0;
			}
			&:before{
				transform: rotate(45deg);
				transform-origin: right top;
				background: $orange;
			}
			&:after{
				transform: rotate(-45deg);
				transform-origin: right bottom;
				background: linear-gradient(to bottom, $orange 0%, $blue 100%);
			}
		}
		.owl-next{
			right: -100px;
			&:before,
			&:after{
				left: 0;
			}
			&:before{
				transform: rotate(-45deg);
				transform-origin: left top;
				background: linear-gradient(to bottom, $orange 0%, $blue 100%);
			}
			&:after{
				transform: rotate(45deg);
				transform-origin: left bottom;
				background: $blue;
			}
		}
	}
}