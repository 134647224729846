.sect1{
	display: flex;
	padding: 80px 0 100px 0;
	.fix &{
		padding: 165px 0 100px 0;
	}
	&__left{
		width: 465px;
		position: relative;
		img{
			display: block;
			position: absolute;
			top: 170px;
    		left: -325px;
		}
	}
	&__right{
		width: calc(100% - 465px);
	}
}