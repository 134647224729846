.sect5{
	padding-bottom: 100px;
	.info{
		width: 750px;
		border-radius: 25px;
		// border-bottom: 2px solid $blue;
		padding: 60px;
		margin-left: -70px;
		background-image: url('/src/images/sect5Info.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;
		p{
			width: 420px;
		}
		img{
			display: block;
			position: absolute;
			
			&.img1{
				top: 0;
				right: -350px;
			}
			&.img2{
				bottom: -592px;
    			right: -304px;
			}
		}
	}
	&__niz{
		width: 465px;
		min-height: 500px;
	}
}