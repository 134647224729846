.topLine{
	width: 100%;
	height: 85px;
	background: #fff;
	position: relative;
	.fix &{
		position: fixed;
		top: 0;
		left: 0;
		z-index: 3;
	}
	&:after{
		display: block;
		content: '';
		width: 100%;
		height: 2px;
		background: #e46425;
		background: -moz-linear-gradient(left,  #e46425 0%, #8f3b8e 50%, #342dbc 100%);
		background: -webkit-linear-gradient(left,  #e46425 0%,#8f3b8e 50%,#342dbc 100%);
		background: linear-gradient(to right,  #e46425 0%,#8f3b8e 50%,#342dbc 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e46425', endColorstr='#342dbc',GradientType=1 );
	}
	&--wrap{
		width: 1200px;
		height: 85px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__logo{
		width: 186px;
		height: 29px;
		background: url('/src/images/logoM.png');
	}
	&__menu{
		ul{
			margin: 0;
			padding: 0;
			display: flex;
		}
		ul,li{
			list-style: none;
		}
		a{
			display: block;
			line-height: 85px;
			color: $blue;
			font-weight: 800;
			text-decoration: none;
			letter-spacing: -0.2px;
			padding: 0 8px;
			transition: color 0.3s ease;
			&:hover{
				color: $orange;
			}
		}
	}
	&__phone{
		font-size: 20px;
		font-weight: 800;
		letter-spacing: -0.2px;
		color: $orange;
	}
}