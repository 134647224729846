body{
	font-family: "ProximaNova";
}

h3{
	font-size: 67px;
	font-weight: bold;
	line-height: 59px;
	margin: 0;
}
h5{
	font-size: 22px;
	font-weight: bold;
	line-height: 26px;
	margin: 0;
}

ul, ol{
	padding: 0;
	margin: 0;
	list-style: none;
	line-height: 22px;
}

.contBl {
	ul, ol{
		li{
			position: relative;
			line-height: 22px;
			padding: 3px 0;
			&:before{
				display: block;
				position: absolute;
				top: 0;
				color: $orange;
			}
		}
	}
	ul{
		span{color: $orange;}
		li{
			&:before{
				content: '—';
				left: -17px;
			}
		}
	}
	ol{
		counter-reset: item;
		li{
			&:before{
				counter-increment: item;
				// content: counter(item);
				content: counters(item, ".");
				left: -14px;
				top: 3px;
			}
		}
	}
}

p{
	font-size: 15px;
	line-height: 22px;
	margin: 0;
	&.bold{
		font-weight: bold;
	}
}
a.orange{
	text-decoration: none;
	color: $orange;
	transition: color 0.3s ease;
	span{
		transition: border 0.3s ease;
		border-bottom: 1px solid $orange;
	}
	&:hover{
		color: $blue;
		span{
			border-color: $blue;
		}
	}
}
.infoBlock{
	padding: 20px 25px;
	border-left: 2px solid $orange;
	border-right: 2px solid $blue;
	position: relative;
	&:before,
	&:after{
		display: block;
		content: '';
		width: 100%;
		height: 2px;
		position: absolute;
		left: 0;
		background: #ff7e00;
		background: -moz-linear-gradient(left, #ff7e00 0%, #3033b9 100%);
		background: -webkit-linear-gradient(left, #ff7e00 0%,#3033b9 100%);
		background: linear-gradient(to right, #ff7e00 0%,#3033b9 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7e00', endColorstr='#3033b9',GradientType=1 );
	}
	&:before{
		top: 0;
	}
	&:after{
		bottom: 0;
	}
}
.ctr{
	margin: 0 auto;
	&930{
		width: 930px;
		margin: 0 auto;
	}
}
.btn{
	display: inline-block;
	border: none;
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	line-height: 45px;
	padding: 0 15px;
	transition: all 0.3s ease;
	&.btn-orange{
		// background: $orange;
		// background-image: linear-gradient(to right, $orange 0%, $orange 100%);
		background: linear-gradient(to right, $orange 0%, $orange 60%, $blue 100%);
		background-size: 200% 100%;
		color: #fff;
		&:hover{
			// background: linear-gradient(to right, $orange 0%, $blue 100%);
			background-position: 100% 0;
		}
	}
}

.price{
	font-size: 40px;
	line-height: 40px;
	font-weight: bold;
	color: $orange;
}