/* font-family: "Regular"; */
@font-face {
    font-family: "ProximaNova";
    src: url("/build/fonts/ProximaNova/ProximaNovaRegular/ProximaNovaRegular.eot");
    src: url("/build/fonts/ProximaNova/ProximaNovaRegular/ProximaNovaRegular.eot?#iefix")format("embedded-opentype"),
    url("/build/fonts/ProximaNova/ProximaNovaRegular/ProximaNovaRegular.woff") format("woff"),
    url("/build/fonts/ProximaNova/ProximaNovaRegular/ProximaNovaRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "ProximaNova";
    src: url("/build/fonts/ProximaNova/ProximaNovaBold/ProximaNovaBold.eot");
    src: url("/build/fonts/ProximaNova/ProximaNovaBold/ProximaNovaBold.eot?#iefix")format("embedded-opentype"),
    url("/build/fonts/ProximaNova/ProximaNovaBold/ProximaNovaBold.woff") format("woff"),
    url("/build/fonts/ProximaNova/ProximaNovaBold/ProximaNovaBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: "ProximaNova";
    src: url("/build/fonts/ProximaNova/ProximaNovaExtrabold/ProximaNovaExtrabold.eot");
    src: url("/build/fonts/ProximaNova/ProximaNovaExtrabold/ProximaNovaExtrabold.eot?#iefix")format("embedded-opentype"),
    url("/build/fonts/ProximaNova/ProximaNovaExtrabold/ProximaNovaExtrabold.woff") format("woff"),
    url("/build/fonts/ProximaNova/ProximaNovaExtrabold/ProximaNovaExtrabold.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: "ProximaNova";
    src: url("/build/fonts/ProximaNova/ProximaNovaSemibold/ProximaNovaSemibold.eot");
    src: url("/build/fonts/ProximaNova/ProximaNovaSemibold/ProximaNovaSemibold.eot?#iefix")format("embedded-opentype"),
    url("/build/fonts/ProximaNova/ProximaNovaSemibold/ProximaNovaSemibold.woff") format("woff"),
    url("/build/fonts/ProximaNova/ProximaNovaSemibold/ProximaNovaSemibold.ttf") format("truetype");
    font-style: normal;
    font-weight: 800;
}