.sect2{
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 100px;
	&__left1{
		width: 580px;
	}
	&__right1{
		width: calc(100% - 580px);
		position: relative;
		img{
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	&__left2{
		width: 50%;
	}
	&__right2{
		width: 50%;
		img{
			margin-top: 150px;
		}
	}
}
.iconZag{
	display: inline-block;
	background: linear-gradient(to left, $orange 0%, $blue 50%,  $blue 100%);  /* подробнее про градиенты */ 
	-webkit-background-clip: text;  /* подробнее про background-clip */ 
	color: transparent;
	font-weight: bold;
	position: relative;
	&:before{
		display: block;
		content: '';
		width: 60px;
		height: 60px;
		position: absolute;
		top: -20px;
		left: -60px;
		background-image: url('/src/images/icons.jpg');
		background-repeat: no-repeat;
	}
	&.ic1:before{background-position: 0 0;}
	&.ic2:before{background-position: 0 -60px;}
	&.ic3:before{background-position: 0 -120px;}
	&.ic4:before{background-position: 0 -180px;}
	&.ic5:before{background-position: 0 -240px;}
	&.ic6:before{background-position: -60px 0;}
	&.ic7:before{background-position: -60px -60px;}
	&.ic8:before{background-position: -60px -120px;}
	&.ic9:before{background-position: -60px -180px;}
	&.ic10:before{background-position: -60px -240px;}
}