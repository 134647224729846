.sect4{
	display: flex;
	padding-bottom: 100px;
	> div{
		width: 50%;
	}
	&__left{
		position: relative;
		img{
			position: absolute;
			top: 200px;
			left: -235px;
		}
	}
	&__right{
		img{
			display: block;
			margin-bottom: 40px;
		}
	}
}