.sect6{
	padding-bottom: 100px;
	margin-top: 100px;
	position: relative;
	img{
		display: block;
		position: absolute;
		&.img1{
			top: 0;
			right: -60px;
		}
		&.img2{
			top: 285px;
    		right: -120px;
		}
	}
	&__niz{
		width: 315px;
	}
}