@charset "UTF-8";
html {
  font-family: sans-serif;
  /* 1 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.35em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/* font-family: "Regular"; */
@font-face {
  font-family: "ProximaNova";
  src: url("/build/fonts/ProximaNova/ProximaNovaRegular/ProximaNovaRegular.eot");
  src: url("/build/fonts/ProximaNova/ProximaNovaRegular/ProximaNovaRegular.eot?#iefix") format("embedded-opentype"), url("/build/fonts/ProximaNova/ProximaNovaRegular/ProximaNovaRegular.woff") format("woff"), url("/build/fonts/ProximaNova/ProximaNovaRegular/ProximaNovaRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "ProximaNova";
  src: url("/build/fonts/ProximaNova/ProximaNovaBold/ProximaNovaBold.eot");
  src: url("/build/fonts/ProximaNova/ProximaNovaBold/ProximaNovaBold.eot?#iefix") format("embedded-opentype"), url("/build/fonts/ProximaNova/ProximaNovaBold/ProximaNovaBold.woff") format("woff"), url("/build/fonts/ProximaNova/ProximaNovaBold/ProximaNovaBold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: "ProximaNova";
  src: url("/build/fonts/ProximaNova/ProximaNovaExtrabold/ProximaNovaExtrabold.eot");
  src: url("/build/fonts/ProximaNova/ProximaNovaExtrabold/ProximaNovaExtrabold.eot?#iefix") format("embedded-opentype"), url("/build/fonts/ProximaNova/ProximaNovaExtrabold/ProximaNovaExtrabold.woff") format("woff"), url("/build/fonts/ProximaNova/ProximaNovaExtrabold/ProximaNovaExtrabold.ttf") format("truetype");
  font-style: normal;
  font-weight: 900; }

@font-face {
  font-family: "ProximaNova";
  src: url("/build/fonts/ProximaNova/ProximaNovaSemibold/ProximaNovaSemibold.eot");
  src: url("/build/fonts/ProximaNova/ProximaNovaSemibold/ProximaNovaSemibold.eot?#iefix") format("embedded-opentype"), url("/build/fonts/ProximaNova/ProximaNovaSemibold/ProximaNovaSemibold.woff") format("woff"), url("/build/fonts/ProximaNova/ProximaNovaSemibold/ProximaNovaSemibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800; }

body {
  font-family: "ProximaNova"; }

h3 {
  font-size: 67px;
  font-weight: bold;
  line-height: 59px;
  margin: 0; }

h5 {
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
  margin: 0; }

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 22px; }

.contBl ul li, .contBl ol li {
  position: relative;
  line-height: 22px;
  padding: 3px 0; }
  .contBl ul li:before, .contBl ol li:before {
    display: block;
    position: absolute;
    top: 0;
    color: #f47421; }

.contBl ul span {
  color: #f47421; }

.contBl ul li:before {
  content: '—';
  left: -17px; }

.contBl ol {
  counter-reset: item; }
  .contBl ol li:before {
    counter-increment: item;
    content: counters(item, ".");
    left: -14px;
    top: 3px; }

p {
  font-size: 15px;
  line-height: 22px;
  margin: 0; }
  p.bold {
    font-weight: bold; }

a.orange {
  text-decoration: none;
  color: #f47421;
  transition: color 0.3s ease; }
  a.orange span {
    transition: border 0.3s ease;
    border-bottom: 1px solid #f47421; }
  a.orange:hover {
    color: #281dcc; }
    a.orange:hover span {
      border-color: #281dcc; }

.infoBlock {
  padding: 20px 25px;
  border-left: 2px solid #f47421;
  border-right: 2px solid #281dcc;
  position: relative; }
  .infoBlock:before, .infoBlock:after {
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    background: #ff7e00;
    background: -moz-linear-gradient(left, #ff7e00 0%, #3033b9 100%);
    background: -webkit-linear-gradient(left, #ff7e00 0%, #3033b9 100%);
    background: linear-gradient(to right, #ff7e00 0%, #3033b9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7e00', endColorstr='#3033b9',GradientType=1 ); }
  .infoBlock:before {
    top: 0; }
  .infoBlock:after {
    bottom: 0; }

.ctr {
  margin: 0 auto; }
  .ctr930 {
    width: 930px;
    margin: 0 auto; }

.btn {
  display: inline-block;
  border: none;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  line-height: 45px;
  padding: 0 15px;
  transition: all 0.3s ease; }
  .btn.btn-orange {
    background: linear-gradient(to right, #f47421 0%, #f47421 60%, #281dcc 100%);
    background-size: 200% 100%;
    color: #fff; }
    .btn.btn-orange:hover {
      background-position: 100% 0; }

.price {
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
  color: #f47421; }

.first {
  height: 100vh;
  background-image: url("/src/images/first.jpg");
  background-position: center;
  background-size: cover;
  text-align: center;
  position: relative; }
  .first__menu {
    width: 1100px; }
    .first__menu ul {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between; }
    .first__menu ul, .first__menu li {
      list-style: none; }
    .first__menu a {
      display: block;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      line-height: 100px;
      padding: 0 15px; }
      .first__menu a span {
        border-bottom: 1px solid #fff;
        transition: border 0.3s ease; }
      .first__menu a:hover span {
        border-color: transparent; }
  .first__logo {
    width: 634px;
    height: 99px;
    background-image: url("/src/images/logo.png");
    background-position: center;
    background-size: container;
    position: absolute;
    top: 35%;
    left: calc(50% - 317px); }
  .first__slogan {
    width: 100%;
    font-size: 62px;
    line-height: 62px;
    font-weight: 900;
    color: #fff;
    position: absolute;
    top: calc(35% + 100px); }
  .first__text {
    width: 100%;
    position: absolute;
    top: calc(35% + 200px); }
  .first__text,
  .first p {
    font-size: 22px;
    line-height: 28px;
    color: #fff;
    letter-spacing: -0.2px; }
  .first__next {
    width: 100%;
    font-size: 22px;
    color: #fff;
    position: absolute;
    bottom: 15%; }
    .first__next:after {
      width: 0;
      display: block;
      content: '';
      border: 13px solid transparent;
      border-top: 13px solid #f47421;
      position: absolute;
      bottom: -30px;
      left: calc(50% - 13px); }

.topLine {
  width: 100%;
  height: 85px;
  background: #fff;
  position: relative; }
  .fix .topLine {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3; }
  .topLine:after {
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    background: #e46425;
    background: -moz-linear-gradient(left, #e46425 0%, #8f3b8e 50%, #342dbc 100%);
    background: -webkit-linear-gradient(left, #e46425 0%, #8f3b8e 50%, #342dbc 100%);
    background: linear-gradient(to right, #e46425 0%, #8f3b8e 50%, #342dbc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e46425', endColorstr='#342dbc',GradientType=1 ); }
  .topLine--wrap {
    width: 1200px;
    height: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .topLine__logo {
    width: 186px;
    height: 29px;
    background: url("/src/images/logoM.png"); }
  .topLine__menu ul {
    margin: 0;
    padding: 0;
    display: flex; }
  .topLine__menu ul, .topLine__menu li {
    list-style: none; }
  .topLine__menu a {
    display: block;
    line-height: 85px;
    color: #281dcc;
    font-weight: 800;
    text-decoration: none;
    letter-spacing: -0.2px;
    padding: 0 8px;
    transition: color 0.3s ease; }
    .topLine__menu a:hover {
      color: #f47421; }
  .topLine__phone {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -0.2px;
    color: #f47421; }

.sect1 {
  display: flex;
  padding: 80px 0 100px 0; }
  .fix .sect1 {
    padding: 165px 0 100px 0; }
  .sect1__left {
    width: 465px;
    position: relative; }
    .sect1__left img {
      display: block;
      position: absolute;
      top: 170px;
      left: -325px; }
  .sect1__right {
    width: calc(100% - 465px); }

.sect2 {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px; }
  .sect2__left1 {
    width: 580px; }
  .sect2__right1 {
    width: calc(100% - 580px);
    position: relative; }
    .sect2__right1 img {
      position: absolute;
      top: 0;
      left: 0; }
  .sect2__left2 {
    width: 50%; }
  .sect2__right2 {
    width: 50%; }
    .sect2__right2 img {
      margin-top: 150px; }

.iconZag {
  display: inline-block;
  background: linear-gradient(to left, #f47421 0%, #281dcc 50%, #281dcc 100%);
  /* подробнее про градиенты */
  -webkit-background-clip: text;
  /* подробнее про background-clip */
  color: transparent;
  font-weight: bold;
  position: relative; }
  .iconZag:before {
    display: block;
    content: '';
    width: 60px;
    height: 60px;
    position: absolute;
    top: -20px;
    left: -60px;
    background-image: url("/src/images/icons.jpg");
    background-repeat: no-repeat; }
  .iconZag.ic1:before {
    background-position: 0 0; }
  .iconZag.ic2:before {
    background-position: 0 -60px; }
  .iconZag.ic3:before {
    background-position: 0 -120px; }
  .iconZag.ic4:before {
    background-position: 0 -180px; }
  .iconZag.ic5:before {
    background-position: 0 -240px; }
  .iconZag.ic6:before {
    background-position: -60px 0; }
  .iconZag.ic7:before {
    background-position: -60px -60px; }
  .iconZag.ic8:before {
    background-position: -60px -120px; }
  .iconZag.ic9:before {
    background-position: -60px -180px; }
  .iconZag.ic10:before {
    background-position: -60px -240px; }

.sect3 {
  padding-bottom: 100px; }
  .sect3__left {
    width: 600px;
    float: left; }
  .sect3__right {
    width: calc(100% - 600px);
    float: left; }
  .sect3__sl__item__desc {
    display: flex;
    font-weight: bold;
    margin-top: 45px; }
  .sect3__sl__item__num {
    font-size: 60px;
    line-height: 52px;
    margin-right: 10px;
    color: #f47421; }
  .sect3__sl__item__text {
    font-size: 22px;
    line-height: 26px; }
  .sect3__sl .owl-nav {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .sect3__sl .owl-prev,
  .sect3__sl .owl-next {
    position: absolute;
    top: 165px;
    width: 60px;
    height: 120px; }
    .sect3__sl .owl-prev:before, .sect3__sl .owl-prev:after,
    .sect3__sl .owl-next:before,
    .sect3__sl .owl-next:after {
      display: block;
      content: '';
      width: 1px;
      height: 86px;
      background: #ccc;
      position: absolute; }
    .sect3__sl .owl-prev:before,
    .sect3__sl .owl-next:before {
      top: 0; }
    .sect3__sl .owl-prev:after,
    .sect3__sl .owl-next:after {
      bottom: 0; }
  .sect3__sl .owl-prev {
    left: -100px; }
    .sect3__sl .owl-prev:before, .sect3__sl .owl-prev:after {
      right: 0; }
    .sect3__sl .owl-prev:before {
      transform: rotate(45deg);
      transform-origin: right top;
      background: #f47421; }
    .sect3__sl .owl-prev:after {
      transform: rotate(-45deg);
      transform-origin: right bottom;
      background: linear-gradient(to bottom, #f47421 0%, #281dcc 100%); }
  .sect3__sl .owl-next {
    right: -100px; }
    .sect3__sl .owl-next:before, .sect3__sl .owl-next:after {
      left: 0; }
    .sect3__sl .owl-next:before {
      transform: rotate(-45deg);
      transform-origin: left top;
      background: linear-gradient(to bottom, #f47421 0%, #281dcc 100%); }
    .sect3__sl .owl-next:after {
      transform: rotate(45deg);
      transform-origin: left bottom;
      background: #281dcc; }

.sect4 {
  display: flex;
  padding-bottom: 100px; }
  .sect4 > div {
    width: 50%; }
  .sect4__left {
    position: relative; }
    .sect4__left img {
      position: absolute;
      top: 200px;
      left: -235px; }
  .sect4__right img {
    display: block;
    margin-bottom: 40px; }

.sect5 {
  padding-bottom: 100px; }
  .sect5 .info {
    width: 750px;
    border-radius: 25px;
    padding: 60px;
    margin-left: -70px;
    background-image: url("/src/images/sect5Info.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative; }
    .sect5 .info p {
      width: 420px; }
    .sect5 .info img {
      display: block;
      position: absolute; }
      .sect5 .info img.img1 {
        top: 0;
        right: -350px; }
      .sect5 .info img.img2 {
        bottom: -592px;
        right: -304px; }
  .sect5__niz {
    width: 465px;
    min-height: 500px; }

.sect6 {
  padding-bottom: 100px;
  margin-top: 100px;
  position: relative; }
  .sect6 img {
    display: block;
    position: absolute; }
    .sect6 img.img1 {
      top: 0;
      right: -60px; }
    .sect6 img.img2 {
      top: 285px;
      right: -120px; }
  .sect6__niz {
    width: 315px; }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y; }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    display: none; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #e5e8f0;
    display: inline-block;
    cursor: pointer; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: #869791;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 13px;
      height: 13px;
      margin: 5px 7px;
      background: #ccc;
      display: block;
      -webkit-backface-visibility: visible;
      transition: background 0.3s ease; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #281dcc; }
